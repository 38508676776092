<template>
    <div>
        <component-container
            :card-title="$t('component_competitorConfiguration_title')"
            :card-subtitle="$t('component_competitorConfiguration_subtitle')"

            :height="780"

            :information-tooltip="{
                title: $t('component_competitorConfiguration_informationTooltip_title'),
                text: $t('component_competitorConfiguration_informationTooltip_text')
            }"

            :component-data="{response:{isLoaded:projectCompetitors.isLoaded}, hasData:true}"
        >
            <template v-slot:content>
                <div id="competitorAddContainer">
                    <input-with-add-button
                        :placeholder="$t('component_competitorConfiguration_addLabel')"
                        @inputWithAddButtonAddValue="addCompetitor"
                    />
                </div>

                <div id="competitorList" v-if="projectCompetitors.data && projectCompetitors.data.length">
                    <vs-list>
                        <vs-list-item
                            v-for="(competitor, index) in projectCompetitors.data"
                            :key="index"
                            :title="competitor.url"
                        >
                            <vs-button type="flat" icon="close" @click="confirmDeleteCompetitors(competitor.id)"></vs-button>
                        </vs-list-item>
                    </vs-list>
                </div>
            </template>
        </component-container>

        <confirm-dialog :title="$t('component_competitorConfiguration_confirmDeleteTitle')" ref="confirmDeleteCompetitors" @confirm-dialog-valid="acceptDeleteCompetitors()">
            <div v-html="$t('component_competitorConfiguration_confirmDeleteText')"></div>
        </confirm-dialog>
    </div>
</template>


<script>

import apiCalls from "@/api/axios/configuration/project";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable";
import Vue from "vue"
import InputWithAddButton from "@/components/yooda-components/form/InputWithAddButton";

export default {

    data() {
        return {
            projectCompetitors:{  isLoaded:false, error:null,data: null },
            competitorToDelete:0,
        }
    },
    components: {
        AgGridTable,
        InputWithAddButton,
    },
    computed:{

    },
    methods:{

        addCompetitor(inputCompetitor){

            let newCompetitors = []
            this.explodeStringToArray(inputCompetitor).forEach((competitor)=>{
                let domainName = this.getDomainNameFromString(competitor)
                if(domainName){
                    if(domainName.trim().length <= 255){
                        newCompetitors.push({url:domainName.toLowerCase()})
                    }
                }
            })

            if(newCompetitors.length>0){
                this.$vs.loading()

                let promise = apiCalls.createCompetitor(this.$route.params.projectId, {rivals:newCompetitors})
                promise.then((response)=>{
                    if(response){
                        this.getComponentData( {
                            componentName:'projectCompetitors',
                            promise: apiCalls.getCompetitors(this.$route.params.projectId)
                        })

                        this.getComponentData( {
                            componentName:'projectInfo',
                            promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter: this.projectData
                        })

                        this.analyticsIdentify()
                    }
                    this.$vs.loading.close()
                }).catch((error)=>{
                    this.displayForbidenError(error.response)
                    this.$vs.loading.close()
                })
            }
        },
        confirmDeleteCompetitors(id){
            this.competitorToDelete = id
            this.$refs.confirmDeleteCompetitors.displayDialog()
        },

        acceptDeleteCompetitors(){
            if(this.competitorToDelete !== 0){
                this.$vs.loading()
                let promise = apiCalls.deleteCompetitors(this.$route.params.projectId, {rivals:[{id:this.competitorToDelete}]})
                promise.then((response)=>{
                    if(response){
                        this.getComponentData({
                            componentName:'projectCompetitors',
                            promise: apiCalls.getCompetitors(this.$route.params.projectId)
                        })


                        this.getComponentData( {
                            componentName:'projectInfo',
                            promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter: this.projectData
                        })


                        this.analyticsIdentify()
                        this.competitorToDelete = 0
                    }
                    this.$vs.loading.close()
                }).catch((error)=>{
                    Vue.error('delete competitors',error)
                })
            }

        },
        initComponentData(){
            this.getComponentData( {
                componentName:'projectCompetitors',
                promise: apiCalls.getCompetitors(this.$route.params.projectId)
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">



#competitorAddContainer{
    margin: 5px 0 32px 0;
    padding: 14px;
    background-color: #f6f6f6;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    .vs-input{
        width: calc(100% - 35px);
        display: inline-block;
    }
    .vs-button{
        display: inline-block;
        vertical-align: bottom;
        height: 37px;
        margin-left: -6px;
    }
}

#competitorList{
    height: 520px;
    overflow: auto;
}

</style>
