<template>
    <div id="competitors-configuration-page">
        <div class="vx-row">
            <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base" >
                <competition-configuration/>
            </div>
        </div>
    </div>
</template>


<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";
import CompetitionConfiguration from "@/components/yooda-components/project/CompetitionConfiguration";

export default {
    components: {
        PagePlaceholder,
        CompetitionConfiguration,
    },
    mounted() {
        this.analyticsIdentify()
    }
}

</script>
